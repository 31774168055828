<template>
  <v-container>
    <v-list>
      <v-list-item
        v-for="event in events"
        :key="event.id"
      >
        <v-list-item-avatar>
          <v-icon>mdi-duck</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{event.title}}</v-list-item-title>

          <v-list-item-subtitle>{{ (new Date(event.publishedtime)).toLocaleString() }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn block :to="`/addEvent/${event.id}`">
            <v-icon color="primary">mdi-fountain-pen</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
import axios from 'axios'
import { getAuthHeader } from '../../utils/tools'

export default {
  data: () => ({
    events: []
  }),
  mounted: function () {
    axios
      .get('/api/events/allAdmin', getAuthHeader())
      .then((result) => {
        this.events = result.data
        console.log(this.events)
      })
  }
}
</script>
